import React, {Component} from 'react'

let GetDataUsers = (params) => {
    let token = "Bearer " + JSON.parse(localStorage.getItem("login")).token;
    console.log(params)
    let url_send = "https://aws.profesorvirtual.cloudns.cl:3000/" + params.url
    console.log(url_send)
    fetch(url_send ,{
      method:"GET",
      headers:{
        "Authorization": token,
      }
    }).then((res) => {
      if(res.ok){
        return res.json()
    }}).then((res) => {
      
    })
    // .then((res)=> {
    //     console.log("Respuesta")
    //     console.log(res.count)
    //     let count_res = res.count
    //     console.log(count_res.filter(d => d.buyed == 1))
        
    //     let comprados = res.count.map((x) => x.buyed)
    //     let hechos = res.count.map((x) => x.done)
    //     let floor = res.count.map((x) => x.floor)
    //     let skill = res.count.map((x) => x.skill)
    //     let room = res.count.map((x) => x.room)

        

    //     // Piso Máximo

    //     let max_floor = Math.max.apply(null, floor)
    //     console.log("Maximo piso")
    //     console.log(max_floor)

    //     for(let i = 0; i < max_floor; i++){

    //     }

    //     let salida = {
    //       comprados: comprados,
    //       hechos: hechos,
    //       floor: floor,
    //       skill: skill,
    //       room: room
    //     }
    //     console.log("Salida - skils")
    //     console.log(salida)
    //     return salida
    // })
}

export default GetDataUsers;