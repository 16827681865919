import './App.css';
import Login from '../src/pages/login/login';
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
// import Login from './pages/login/login';
import NotFound from './pages/404/404';
import Students from './pages/students/students'
import Analisis from './pages/analitic/analitic'
import Groups from './pages/groups/groups'
import AsyncHooks from './pages/pruebas'
import LoginSSO from './pages/loginSSO/loginsso'
import Home from './pages/home/home'
import Report from './pages/report/report'
import Temporal from './pages/temporal/temporal'

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path = "/groups" component = {Groups}></Route>
        <Route exact path = "/" component = {LoginSSO}></Route>
        <Route exact path = "/temporal" component = {Temporal}></Route>
        <Route exact path = "/analisis" component = {Analisis}></Route>
        <Route path = "/analisis/:id" componet ={Analisis}></Route>
        <Route exact path = "/login" component = {Login}/>
        <Route exact path = "/Students" component = {Students}></Route>
        <Route exact path = "/login" component = {Login}/>
        <Route exact path = "/404" component = {NotFound}/>
        <Route exact path = "/pruebaHook" component = {AsyncHooks}/>
        <Route exact path = "/loginsso" component = {LoginSSO}/>
        <Route exact path = "/home" component = {Home}/>
        <Route exact path = "/report" component = {Report}/>
        <Redirect to="/404"/>
      </Switch>
    </Router>
    // <Login></Login>
  );
}

export default App;
