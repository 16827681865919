import React from 'react';
import Plot from 'react-plotly.js';

export default function GraficoFloor(props){
    let graficos = []
    if(props.dataFloor.length != 0){
      graficos.push(
        <Plot
          data={props.dataFloor}
          layout={ {
            width: 500,
            height: 450,
            title: 'Desempeño por habilidad por piso',
            xaxis: {title: 'Piso'},
            yaxis: {title: 'Porcentaje'}
        } }
        />
      )
    }else{
      graficos.push(<div></div>)
    }
    return (
      <div>
        {graficos}
      </div>
    );
}
