import {React, useState, useEffect} from 'react'
import Grid from '@material-ui/core/Grid';
import Floor from './floor';
import './count.css'
import Select from 'react-select'

const CountBook = (props) =>{

    const [selectedOption, setSelectedOption] = useState();
    const [selectedSkill, setSelectedSkill] = useState("Vocabulary");

    let skills = props.skills
    
    // setSelectedSkill(skills.Vocabulary)

    let options = [
        {value: 'Vocabulary', label: 'Vocabulary'},
        {value: 'Reading', label: 'Reading'},
        {value: 'Grammar', label: 'Grammar'},
        {value: 'Listening', label: 'Listening'}]

    let done = []
    let buyed = []
    let without_buy = []
    let total = []
    let num_floor = 0
    let tabla_count = []

    if(Object.keys(skills).length == 0){
        tabla_count.push(
            <div></div>
        )
    }
    else{
        num_floor = skills[selectedSkill].done.length
        done = skills[selectedSkill].done
        buyed = skills[selectedSkill].buyed
        without_buy = skills[selectedSkill].without_buy
        total = skills[selectedSkill].total
    }

    let items = [];
    for (var i = 0; i < num_floor; i++) {
      items.push(
        <Grid item={true} sm = {12}>
            <Floor skills = {props.skills}
                    done = {done}
                    buyed = {buyed}
                    without_buy = {without_buy}
                    total = {total}
                    piso = {i+1}>
            </Floor>
        </Grid>
        )
    }

    const handleChange = e => {
        setSelectedSkill(e.value);
      }
    
    if(items.length != 0){
        tabla_count.push(
            <div className = 'pisos'>
            <div className = "select">
                <Select id = "selectorSkill" sm = {3} options={options} onChange={handleChange}/>
            </div>
            <Grid container sm = {12}>
                <Grid item={true} sm = {1}></Grid>
                <Grid item={true} sm = {1}></Grid>
                <Grid item={true} className= 'recamara' sm = {1}>Recamara 1</Grid>
                <Grid item={true} className= 'recamara' sm = {1}>Recamara 2</Grid>
                <Grid item={true} className= 'recamara' sm = {1}>Recamara 3</Grid>
                <Grid item={true} className= 'recamara' sm = {1}>Recamara 4</Grid>
                <Grid item={true} className= 'recamara' sm = {1}>Recamara 5</Grid>
                <Grid item={true} className= 'recamara' sm = {1}>Recamara 6</Grid>
                <Grid item={true} className= 'recamara' sm = {1}>General</Grid>
                <Grid item={true} sm = {3}></Grid>
            </Grid>
            <Grid container sm = {12}>
                {items}
            </Grid>
            </div>
        )
    }else{
        tabla_count.push(
        <Grid container>
            <Grid sm = {1}>
            </Grid>
            <Grid sm = {10}>
                <p>El estudiante no ha realizado aún la prueba diagnóstica</p>
            </Grid>
        </Grid>
        )
    }

    return(
        <div>
            {tabla_count}
        </div>
    )
}

export default CountBook;