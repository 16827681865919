import {React, useState, useEffect} from 'react';
import {Link} from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import './groupBar.css'

const GroupBar = (props) => {

   let course_group = props.course_group
   let id_semester = props.id_semester
   let id_course = props.id_course
   
    const getInfoUsers = () => {
        let token = "Bearer " + JSON.parse(localStorage.getItem("login")).token;
        fetch("https://aws.profesorvirtual.cloudns.cl:3000/users/info/" + course_group + "/group/" + id_semester + "/semester/" + id_course + "/course",{
          method:"GET",
          headers:{
            "Authorization": token,
          }
        }).then((res) => {
          if(res.ok){
            return res.json()
        }
    }).then((res) => {
        localStorage.setItem("UserInfo", JSON.stringify({
            user: res
        }))
        localStorage.setItem("CurrentGroup", JSON.stringify({
          course_group: course_group,
          id_semester: id_semester,
          id_course: id_course
        }))
          // window.location.assign("https://" + window.location.host + "/students")
            return res
        }).catch((e) => {
          console.log("ERROR")
          console.log(e)
        })
      }

    return(
        <Grid container>
          <Grid item sm = {12}>
             &nbsp;
          </Grid>

            <Grid sm = {2} ></Grid>
            <Grid sm = {10}>
                <Grid item sm = {1}>
                </Grid>
                
                <Grid container sm = {10} className= "groups">
                  <Grid item sm = {6} className = "group-text">
                      {props.name_course} - Grupo {course_group}
                  </Grid>
                    <Grid item sm = {3}>
                    <Link key={"link_contest"} to={{
                            pathname: '/students',
                            state: {
                                course_name: props.name_course,
                                course_group: course_group,
                                id_semester: id_semester,
                                id_course: id_course,
                            }
                        }}
                            className="link-student">
                        <button className = "boton-st" type = "button" onClick = {getInfoUsers}>
                          Ver Estudiantes
                          </button>
                        </Link>
                    </Grid>
                  <Grid item sm = {2}>
                  <Link key={"link_contest"} to= {{
                                pathname: '/report',
                                state: {
                                  course_name: props.name_course,
                                  course_group: course_group,
                                  id_semester: id_semester,
                                  id_course: id_course
                                }
                              }}
                              className = "link-student">
                                <button className = "boton-st">
                                  Turo Contest
                                </button>
                            </Link>
                  </Grid>
                </Grid>

                <Grid item sm = {1}>
                </Grid>
            </Grid>
            <Grid sm = {1} ></Grid>
        </Grid>
    )
}

export default GroupBar;

