import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import "./barmenu.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faBookReader } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faChartArea } from "@fortawesome/free-solid-svg-icons";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import logoTuro from '../../assets/images/LogoTuro3.png'
import urDigitalBlanco from '../../assets/images/urDigitalBlanco.png'


export default function BarMenu() {

    const cont = () => {

        let elem = document.querySelector("#menu-turo")
        
        if(elem.style.display == "none"){
            elem.style.display = "block"
        }else{
            elem.style.display = "none"
        }
    }


    return(
        <div>
            <Grid>
                <Grid container className="superior">
                    <Grid item sm = {1}>
                        <button className = "menu-button" onClick = {cont}>
                            <FontAwesomeIcon icon={faBars} />
                        </button>
                    </Grid>
                    <Grid item sm= {3}>
                            <h1 className = "texto-menu">Turo Analítica</h1>
                    </Grid>
                    <Grid item md = {4}>
                        <Hidden className = "logo-menu" xsDown>
                            <a href = "/groups">
                                <img src = {logoTuro}></img>
                            </a>
                        </Hidden>
                    </Grid>
                    <Grid sm = {4}>
                        <Hidden className = "logo-menu" xsDown>
                            <img className = "logo-menu" src = {urDigitalBlanco}></img>
                        </Hidden>
                    </Grid>
                </Grid>

                <Grid sm = {2} className = "menu-con" id = "menu-turo">
                    <div className = "menu-res">
                        <div className = "menu-contenido">
                            <a href = "/groups" className = "menu-element"> <span><FontAwesomeIcon icon={faUsers} /></span> <span className = "text-menu-element">Grupos</span> </a>
                            <a href = "/" className = "menu-element"> <span><FontAwesomeIcon icon={faSignOutAlt} /></span> <span className = "text-menu-element">Log out</span>  </a>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}