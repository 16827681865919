import React from 'react';
import Grid from '@material-ui/core/Grid';
import logoFinal from '../../assets/images/LogoFinal.png'
import logoUrdigital from '../../assets/images/urdigital.png'
import {Link} from 'react-router-dom'

import './loginsso.css'


export default function GraficoFloor(props){
    return (
      <div className = "rootl">
        <Grid container>

          <Grid xs = {12} sm = {6}>
            <img src = {logoUrdigital} className = "logourdigital"></img>
          </Grid>

          <Grid xs = {0} sm = {4}></Grid>

          <Grid md = {12} className = "sub-dos">
            <h1 className = "tituloTuro">Turo Analytics</h1>
            <p>Welcome to the analytics service for the English area courses at Universidad del Rosario</p>
          </Grid>

          <Grid xs = {12} lg = {12}>
          {/* <Link key={"link_contest"} to= {{
                                pathname: '/report',
                                state: {
                                  group: 1,
                                }
                              }}
                              className = "link-student">
                                  <button className= "boton-gen botongen-grande">Login</button>
          </Link> */}

            <a href = "https://aws.profesorvirtual.cloudns.cl:1337">
              <button className= "boton-gen botongen-grande">Login</button>
            </a>
          </Grid>

          <Grid xs = {12}>
            <img src = {logoFinal} className = "logoturo"></img>
          </Grid>
        </Grid>

      </div>
    );
}
