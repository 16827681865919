import React from 'react';
import Plot from 'react-plotly.js';

export default function Grafico(props){
  let graficos = []
  if(props.data.length != 0){
    graficos.push(      
      <Plot
        data={[
          {
            type: 'scatter',
            marker: {color: '#C8A2C8'},
          },
          {
            type: 'bar',
            x: props.skills,
            y: props.data,
            marker:{
              color:"#C8A2C8",
              line: {
                width: 1.5
              }
            }
          },
        ]}
        layout={ {
          width: 500,
          height: 450,
          title: 'Desempeño general por habilidad',
          xaxis: {title: 'Habilidad'},
          yaxis: {title: 'Porcentaje'}} }
      />
    )
  }else{
    graficos.push(<div></div>)
  }
    return (
      <div>
        {graficos}
      </div>
    );
}
