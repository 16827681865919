import React from 'react';
import './tittle.css'
import Grid from '@material-ui/core/Grid';


const Title = ({text, subtitle}) =>{
    if(!subtitle){
        subtitle = "";
    }
    return(
        <div>           
            <Grid container
            className = "title-container"
            direction = "column"
            justify = "center"
            alignItems = "center"
            >
                <Grid item sm = {12}>
                    <h1 className= "tittle">{text}</h1>
                    <div className = "line"></div>
                    <p className = "subtitle">{subtitle}</p>
                </Grid>
            </Grid>
        </div>
    )
};

export default Title;