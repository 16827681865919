import {React, useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import "./analitic.css"
import Grafico from '../analitic/components/graficos/graficos'
import GetDataUsers from './analitic_data'
import Cell from './components/grid/cell'
import CountBook from './components/grid/count'
import BarMenu from '../../commons/barMenu/barmenu'
import GraficoFloor from '../analitic/components/graficos/graficoFloor'
import GoBack from '../../commons/backButton/back'

const Analisis = (props) =>  {
  
    const [conteoState, setConteos] = useState({count:[{"total_books": "10"}]})
    const [maxfloor, setMaxFloor] = useState()
    const [infoCount, setInfoCount] = useState({})
    const [userName, setUserName] = useState()
    const [dataGrafico, setDataGrafico] = useState([])
    const [dataSkills, setDataSills] = useState([])
    const [dataFloorSkills, setdataFloorSkills] = useState([])


    useEffect(() => {

        let token = "Bearer " + JSON.parse(localStorage.getItem("login")).token;
        // let token = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEyIiwibmFtZSI6Ikp1Z2Fkb3IgMTAgVGVzdCIsInJvbGVzIjpbInRlYWNoZXIiXSwiaWF0IjoxNjI5ODk2MjY2LCJleHAiOjE2Mjk5ODI2NjZ9.FuX0hINUB-NktWKACnJHkcviqffPt_OgYCIh5XCCLgY"
        let url_send = "https://aws.profesorvirtual.cloudns.cl:3000/" + "user-info/"+ props.location.state.id_user + "/count-books-by-room-skill"
        let url_send_performance = "https://aws.profesorvirtual.cloudns.cl:3000/" + "/user-info/"+ props.location.state.id_user + "/performance-by-skill"

        let salidaUsers = JSON.parse(localStorage.getItem("UserInfo")).user.filter(user => user.user_id == props.location.state.id_user)

        function onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
          }

        const fetchData =
            async () => {
                const result = await fetch(url_send,{
                    method:"GET",
                    headers:{
                    "Authorization": token}
                }).then((res) => {
                    if(res.ok){
                    return res.json()
                }}).then(res => {
                    return res
                }).then((res) => {
                    
                    let pisos = []
                    
                    for(let i = 0; i < res.count.length; i++){
                        pisos[i] = parseInt(res.count[i].floor)
                    }
                    
                    let maxFloor = Math.max(...pisos)
                    setMaxFloor(maxFloor)

                    let skills = {"Vocabulary":{"done": [], "buyed": [], "without_buy": [], "total": []},
                                  "Reading":{"done": [], "buyed": [], "without_buy": [], "total": []},
                                  "Grammar":{"done": [], "buyed": [], "without_buy": [], "total": []},
                                  "Listening":{"done": [], "buyed": [], "without_buy": [], "total": []}}
                    
                    if(res.count.length != 0){
                        
                        let skills_names = Object.keys(skills)
                        for(let w = 0; w < skills_names.length ; w++){
                            let temp_skill = res.count.filter(d => d.skill == skills_names[w])
                            let done = Array(maxFloor).fill().map(()=>Array(7).fill())
                            let buyed = Array(maxFloor).fill().map(()=>Array(7).fill())
                            let without_buy = Array(maxFloor).fill().map(()=>Array(7).fill())
                            let total = Array(maxFloor).fill().map(()=>Array(7).fill())
                            for(let i = 0; i < maxFloor; i++){
                                let temp = temp_skill.filter(d => d.floor == i+1)
                                for(let j = 0; j < 6; j++){
                                    if(temp.length == 0){
                                        done[i][j] = 0
                                        buyed[i][j] = 0
                                        without_buy[i][j] = 0
                                        total[i][j] = 0
                                    }else{
                                        let temp_room = temp.filter(d => d.room == j+1)[0]

                                        if(typeof(temp_room) == "undefined"){
                                            done[i][j] = 0
                                            buyed[i][j] = 0
                                            without_buy[i][j] = 0
                                            total[i][j] = 0
                                        }else{
                                            done[i][j] = parseInt(temp_room.done)
                                            buyed[i][j] = parseInt(temp_room.buyed)
                                            without_buy[i][j] = parseInt(temp_room.without_buy)
                                            total[i][j] = parseInt(temp_room.total_books)
                                        }
                                    }                   
                                }
                                done[i][6] = done[i].slice(0, 6).reduce((a,b) => a+b)
                                buyed[i][6] = buyed[i].slice(0, 6).reduce((a,b) => a+b)
                                without_buy[i][6] = without_buy[i].slice(0, 6).reduce((a,b) => a+b)
                                total[i][6] = total[i].slice(0, 6).reduce((a,b) => a+b)
                            }
    
                            skills[skills_names[w]].done = done
                            skills[skills_names[w]].buyed = buyed
                            skills[skills_names[w]].without_buy = without_buy
                            skills[skills_names[w]].total = total
                        }
                    }
            
                    return skills
                }).then(res => {
                    setInfoCount(res)
                })
            }


        const fetchDataProgess =
        async () => {
            const result = await fetch(url_send_performance,{
                method:"GET",
                headers:{
                "Authorization": token}
            }).then((res) => {
                if(res.ok){
                return res.json()
            }}).then(res => {
                return res
            }).then(res => {
                let dataG = res.total;
                setDataSills(dataG.map(d => d.skill))
                setDataGrafico(dataG.map(d => d.percent*100))
                let dataPisos = res.result
                let maxPisos = Math.max(...dataPisos.map(d => d.floor))
                let skillsPiso = dataPisos.map(d => d.skill).filter(onlyUnique)
                let salidaG = []
                let colors ={"Reading": "rgba(25, 56, 31, 0.7)",
                "Listening":"rgba(47, 112, 140, 0.7)",
                "Vocabulary":"rgba(153, 22, 4, 0.7)",
                "Grammar":"rgba(65, 20, 54, 0.7)"}
                let lineColors ={"Reading": "rgba(25, 56, 31, 0.85)",
                "Listening":"rgba(47, 112, 140, 0.85)",
                "Vocabulary":"rgba(153, 22, 4, 0.85)",
                "Grammar":"rgba(65, 20, 54, 0.85)"}
                for(let skill in skillsPiso){
                    let tempPisoG = dataPisos.filter(d => d.skill == skillsPiso[skill])
                    let tempSk = {
                        x : tempPisoG.map(d => d.floor),
                        y : tempPisoG.map(d => d.performance*100),
                        name: skillsPiso[skill],
                        type: 'bar',
                        marker: {
                            color: colors[skillsPiso[skill]],
                            line: {
                              color: lineColors[skillsPiso[skill]],
                              width: 1
                            }
                        }
                    }

                    salidaG.push(tempSk)
                }

                setdataFloorSkills(salidaG)
                return(res)
            }).catch(e => {
                let elem = document.querySelector("#graficos")
                elem.style.display = 'none'
            })
        }

        fetchDataProgess();
        fetchData();

    }, []);


    const id = "";
        return(
            <div>
            <BarMenu></BarMenu>
            <Grid container>
                <Grid item={true}>
                    <h1 className = "titulo"> <GoBack/> {props.location.state.user_name}{id}</h1>
                    <h2 id="datos_prueba"></h2>
                </Grid>
            </Grid>
            
            <Grid container id = "graficos">
                <Grid sm = {1}></Grid>
                <Grid item={true} sm = {5}>
                    <Grafico data = {dataGrafico} skills = {dataSkills} ></Grafico>
                </Grid>
                <Grid item={true} sm = {5}>
                    <GraficoFloor dataFloor = {dataFloorSkills}></GraficoFloor>                    
                </Grid>
                <Grid sm = {1}></Grid>
            </Grid>

            <Grid container>
                <Grid item={true} sm = {12}>
                    <CountBook skills = {infoCount}>
                    </CountBook>
                </Grid>
            </Grid>
            </div>
        )
    }

export default Analisis;
