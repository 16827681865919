import {React, useState, useEffect} from 'react';


export default function Temporal(){
    // EN PRODUCCION 
    const queryString  = window.location.search
    const urlParams  = new URLSearchParams(queryString)
    const token = urlParams.get('token') 

    localStorage.setItem("login", JSON.stringify({
        login: true,
        token: token
    }))

    setTimeout(() => {
        console.log("https://" + window.location.host + "/groups")
        
        window.location.assign("https://" + "turo-analytics.urosario.edu.co" + "/groups")
        // window.location.assign("https://" + window.location.host + "/groups")
    }, 100)

    return(
        <div>
            <p>Bienvenidos</p>
        </div>
    )
}