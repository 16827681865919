import { React, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TableStudentsContest from "./components/Table/table"
import "../report/report.css"
import BarMenu from '../../commons/barMenu/barmenu'
import GoBack from '../../commons/backButton/back'
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Button from '../../commons/Button/button';
import { Link } from 'react-router-dom';


export default function Report(props) {

    let course_group = props.location.state.course_group
    let id_semester = props.location.state.id_semester
    let id_course = props.location.state.id_course

    const [selectedDateStart, setSelectedDateStart] = useState(new Date(2021, 1, 1));
    const [selectedHourStart, setSelectedHourStart] = useState(new Date());
    const [selectedDateEnd, setSelectedDateEnd] = useState(new Date());
    const [selectedHourEnd, setSelectedHourEnd] = useState(new Date());

    const [dateStartFetch, setdateStartFetch] = useState("2021-01-01");
    const [hourStartFetch, sethourStartFetch] = useState("19:35:07");
    const [dateEndFetch, setdateEndFetch] = useState("2021-10-05");
    const [hourEndFetch, sethourEndFetch] = useState("16:20:07");

    const [urlSend, setUrlSend] = useState("/users/report/" + course_group + "/group/" + id_semester + "/semester/" + id_course + "/course/2021-01-01 19:35:07/date_start/2021-08-05 16:20:07/date_end");
    const [table, setTable] = useState(<div></div>)

    // set isMounted to false when we unmount the component
    useEffect(() => {
    }, [])

    let $salida_prueba = "Este valor"

    function handleHourChange(date) {
        let hora = date.getHours()
        let minutos = date.getMinutes()
        let segundos = date.getSeconds()

        if (hora < 10) {
            hora = "0" + hora
        }
        if (minutos < 10) {
            minutos = "0" + minutos
        }
        if (segundos < 10) {
            segundos = "0" + segundos
        }
        return (String(hora) + ":" + String(minutos) + ":" + String(segundos))
    }

    function handleDateChange(date) {
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let fecha = ""

        if (month < 10) {
            month = "0" + month
        }
        if (day < 10) {
            day = "0" + day
        }

        fecha = String(year) + "-" + String(month) + "-" + String(day)
        return (fecha)
    }

    const getInfoContest = () => {
        let url = "/users/report/" + course_group + "/group/" + id_semester + "/semester/" + id_course + "/course/" + dateStartFetch + " " + hourStartFetch + "/date_start/" + dateEndFetch + " " + hourEndFetch + "/date_end"
        let token = "Bearer " + JSON.parse(localStorage.getItem("login")).token;
        let url_send = "https://aws.profesorvirtual.cloudns.cl:3000" + url
        fetch(url_send, {
            method: "GET",
            headers: {
                "Authorization": token,
            }
        }).then((res) => {
            if (res.ok) {
                return res.json()
            }
        }).then((res) => {
            setTable(<TableStudentsContest datosContest={res}></TableStudentsContest>)
            setTimeout(() => {
                document.querySelector("#salvavidas").click()
            }, 100)
        })
    }

    return (
        <Grid container>
            <Grid sm={12}>
                <BarMenu></BarMenu>
            </Grid>

            <Grid item={true} sm={12}>
                <h1 className="titulo"> <GoBack/> Reporte del grupo {props.location.state.group}</h1>
                <Grid container>
                    <Grid item sm={6}>
                        <div className="seleccion">Selecciona una fecha de inicio y fin del reto</div>
                    </Grid>
                    <Grid item sm={2}>
                        <Link key={"link_contest"} to={{
                            pathname: '/report',
                            state: {
                                course_group: course_group,
                                id_semester: id_semester,
                                id_course: id_course,
                            }
                        }}
                            className="link-student">
                            <button onClick={getInfoContest} className="button-ver" id="request">Consultar</button>
                        </Link>
                    </Grid>
                </Grid>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>

                    <div className="picker-cont">
                        <Grid container justify="space-around">
                            <Grid item sm={4} >
                                <p> Desde </p>

                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Fecha de inicio"
                                    value={selectedDateStart}
                                    onChange={(date) => {
                                        let salida_date = handleDateChange(date)
                                        $salida_prueba = salida_date
                                        setdateStartFetch(salida_date)
                                        setSelectedDateStart(date)
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />

                                <KeyboardTimePicker
                                    margin="normal"
                                    id="time-picker"
                                    label="Hora de inicio"
                                    onChange={(date) => {
                                        let salida_date = handleHourChange(date)
                                        sethourStartFetch(salida_date)
                                        setSelectedHourStart(date)
                                    }}
                                    value={selectedHourStart}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                />
                            </Grid>

                            <Grid sm={4}>
                                <p> Hasta </p>

                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Fecha de finalización"
                                    value={selectedDateEnd}
                                    onChange={(date) => {
                                        let salida_date = handleDateChange(date)
                                        setdateEndFetch(salida_date)
                                        setSelectedDateEnd(date)
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                                <KeyboardTimePicker
                                    margin="normal"
                                    id="time-picker"
                                    label="Hora de finalización"
                                    value={selectedHourEnd}
                                    onChange={(date) => {
                                        let salida_date = handleHourChange(date)
                                        sethourEndFetch(salida_date)
                                        setSelectedHourEnd(date)
                                    }
                                    }
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                />
                            </Grid>

                        </Grid>
                    </div>
                </MuiPickersUtilsProvider>

                <Grid container>
                    <Grid sm={1}></Grid>
                    <Grid sm={10} className="tabla">
                        {table}
                    </Grid>
                    <Grid sm={1}></Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
