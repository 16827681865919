import React from 'react';
import './input.css'


const Input = () =>{
    return( 
        <form id = "loginForm">
            <input type="text" name="email" placeholder="Usuario" className = "input"/>
            <br/>
            <input type="text" name = "password" type ="password" placeholder="Contraseña" className = "input"/>
            <br/>
            <input type="submit" value="Iniciar Sesión" id="loginSub" hidden/>
        </form>
    )
};

export default Input;