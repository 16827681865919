import {React, useState, useEffect} from 'react';
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const GoBack = () => {
    return(
        <span>
            <span className = "back" href = "/students" onClick = {() => {window.history.back()}}><FontAwesomeIcon icon={faArrowLeft} /></span>
        </span>
    )
}

export default GoBack;
