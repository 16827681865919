import React from 'react'
import './logo.css'

const Logo = () => {
    return(
        <div>
            <div className = "logo"></div>
        </div>
    )
}

export default Logo;