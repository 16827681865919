import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TableStudents from "./components/Table/table"
import "./students.css"
import BarMenu from '../../commons/barMenu/barmenu'
import GoBack from '../../commons/backButton/back'
import { Link, Switch, Route, matchPath} from 'react-router-dom';
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export default function Students(props) {

    let course_group = props.location.state.course_group
    let id_semester = props.location.state.id_semester
    let id_course = props.location.state.id_course

    console.log("course_group", course_group)
    console.log("id_semester", course_group)
    console.log("id_course", course_group)

    return(
        <Grid container>
            <Grid sm = {12}>
                <BarMenu></BarMenu>
            </Grid>
        <Grid item={true} sm = {12}>
            
            <h1 className = "titulo"> <GoBack/> Estudiantes</h1>
            </Grid>
            <Grid>                
            <p className = "subtitulo">Estudiantes matriculados en {props.location.state.course_name} - Grupo {course_group} </p>
        </Grid>
        <Grid item ={true} sm = {4}>                
            <Link key={"link_contest"} to= {{
                                pathname: '/report',
                                state: {
                                    course_group: course_group,
                                    id_semester: id_semester,
                                    id_course: id_course,
                                }
                              }}
                              className = "link-student">
                                <button className = "button-detalles">
                                  Turo Contest
                                </button>
                            </Link>
            
        </Grid>
        <Grid container>
            <Grid sm = {1}></Grid>
            <Grid sm = {10} className = "tabla">
                <TableStudents></TableStudents>
            </Grid>
            <Grid sm = {1}></Grid>
        </Grid>
        </Grid>
    )
}