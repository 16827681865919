import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import { Link, matchPath} from 'react-router-dom';
import UserIcon from '../usericon/usericon'
import ModalStudent from '../ModalStudent/modal'
import './table.css'

// 

const columns = [
    // {
    //   id: 'foto',
    //   label: 'Foto',
    //   url: "user.png",
    //   align: "center"
    // },
    { 
      id: 'firstName',
      label: 'Nombre',
      minWidth: 150
    },
    { 
      id: 'lastName',
      label: 'Apellido',
      minWidth: 150
    },
    {
      id: 'last_sign_in',
      label: 'Último ingreso',
      // minWidth: 160,
      align: 'center',
    },
    // {
    //   id: 'time_game',
    //   label: 'Tiempo Jugado',
    //   // minWidth: 160,
    //   align: 'center',
    // },
    {
      id: 'intentados',
      label: 'Intentados',
      // minWidth: 160,
      align: 'center',
    },
    {
      id: 'pasados',
      label: 'Pasados',
      // minWidth: 160,
      align: 'center',
    },
    {
      id: 'floor',
      label: 'Piso Máximo',
      // minWidth: 120,
      align: 'center',
      //   format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'detalles',
      label: 'Detalles',
      // minWidth: 100,
      align: 'center',
      //   format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'id_user',
      label: 'Desempeño'
    },
  ];
    
    
  const getParams = (pathname) => {
    const matchProfile = matchPath(pathname, {
      path: '/analisis/:id'
    });
    return (matchProfile && matchProfile.params) || {};
  }


  function dhm(t){

    var cd = 24 * 60 * 60 * 1000,
        ch = 60 * 60 * 1000,
        d = Math.floor(t / cd),
        h = Math.floor( (t - d * cd) / ch),
        m = Math.round( (t - d * cd - h * ch) / 60000),
        pad = function(n){ return n < 10 ? '0' + n : n; };
  if( m === 60 ){
    h++;
    m = 0;
  }
  if( h === 24 ){
    d++;
    h = 0;
  }
  return [d, h, m];
  }

  function compareName(id, comp) {
    if(id == comp){
    }
  }

  const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    container: {
      maxHeight: 440,
    },
    field:{
      textTransform: "capitalize"
    },
  });
  
// userprogam.program.program
// userPrograms.semestre
// userProgram.credcon
// 

  export default function TableStudents() {
    
    const [modalOpen, setModalOpen] = useState(false)
    const [currentStudent, setCurrentStudent] = useState({})
    const [rows, setRows] = useState([])
    const [salidaBackTemp, setSalidaBack] = useState({})

    useEffect(()=>{
  
      let salidaBack = JSON.parse(localStorage.getItem("UserInfo")).user
      
      const rows_temp = [];
      let hoy = new Date()
      console.log("SALIDA BACK")
      console.log(salidaBack)
      if(typeof(salidaBack) != "undefined"){
        for(var i = 0; i< salidaBack.length; i++){
          console.log("ID_USER: " + salidaBack[i].id_user)
          let fecha_ingreso = new Date(salidaBack[i].last_sign_in)
          let tiempo_jugado = "2horas, 30min"
          console.log("AQUI LA INFO DEL USER SOLAMENTE")
          console.log(salidaBack[i].gameUserInfo)
          let intentados = 0
          let pasados = 0
          try{
            intentados = salidaBack[i].gameUserInfo.books_nopassed
            pasados = salidaBack[i].gameUserInfo.books_passed
          }catch(e){
            console.log("Usuario sin infomracion")
          }
          let diferencia = dhm(hoy - fecha_ingreso)
          let fecha_texto = ""
          if(diferencia[0] == 0){
            if(diferencia[1] == 0){
              fecha_texto = "Hace " + diferencia[2] + " Minutos"
            }else{
              fecha_texto = "Hace " + diferencia[1] + " Horas"
            }
          }else{
            fecha_texto = "Hace " + diferencia[0] + " Días"
          }
          rows_temp[i] = {foto: "foto", id_user: salidaBack[i].id_user, firstName: salidaBack[i].firstName , lastName: salidaBack[i].lastName, last_sign_in: fecha_texto, time_game: tiempo_jugado, intentados: intentados, pasados:pasados, floor: salidaBack[i].floor, detalles: i}
        }
      }
      setRows(rows_temp)
      setSalidaBack(salidaBack)
    }, [])

    
    
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    const modalClose = () => {
      setModalOpen(false)
    }

    const modalStuden = (studenInfo) => {
      setModalOpen(true)
      setCurrentStudent(studenInfo)
    };


    return (
      <div>
        <div>
          <ModalStudent onClick = {modalClose} open = {modalOpen} student = {currentStudent}></ModalStudent>
        </div>
      <div>
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table aria-label="sticky table">
                <TableHead className = "table-head">
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        <p className = "table-head">{column.label}</p>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
              <TableBody>
                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        let nombre = row.firstName + " " + row.lastName;
                        let salida = "";
                        let current_val = ""
                        let data = [{data:value}]
                        if(value == "foto"){
                          salida = <UserIcon key={"user" + row}></UserIcon>;
                        }else{
                          salida = <p key={"value"+column.id}>{value}</p>;
                        }
                        if(column.id == "id_user"){
                          return(
                            <TableCell key={column.id} align={column.align}>
                            <Link key={"link" + column.id} to= {{
                                pathname: '/analisis',
                                state: {
                                  id_user: value,
                                  user_name: nombre,
                                }
                              }}
                              className = "link-student">
                                <button className = "button-detalles">
                                  Informe
                                </button>
                            </Link>
                          </TableCell>
                          );
                          console.log("Paso por la zona de id_user" + salida)
                        }
                        if(column.id == "detalles"){
                          return(
                            <TableCell key={column.id} align={column.align}>
                               <button className = "button-detalles" onClick ={() => modalStuden(salidaBackTemp[value])}>Más detalles</button>
                          </TableCell>
                          )
                        }
                        else{
                          return (
                            <TableCell key={column.id} align={column.align}>
                                <p className={classes.field}>{salida}</p>
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            key = "Pagination"
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
      </div>
    );
  }

// export default Students;
