import React from 'react';
import { Component } from 'react';

import './button.css'

class Button extends Component{
    constructor(){
        super();
        this.state = {
            email:null,
            password:null,
            login:false,
            store:null
        }
    }

    login(){
        const myform = document.querySelector("#loginForm")
    
        myform.addEventListener("submit", function(e){
            e.preventDefault();
        
            const formData = new FormData(this);
            let jsonData = JSON.stringify(Object.fromEntries(formData));
        
            fetch("https://aws.profesorvirtual.cloudns.cl:3000/users/login", {
                method: "post",
                body: jsonData,
                headers: {
                    'Content-Type': 'application/json',
                  },
            }).then((res) => {
                if(res.ok){
                    return res.json()
                }
            }).then(user => {
              
                localStorage.setItem("login", JSON.stringify({
                    login: true,
                    token: user.token
                }))

                if(user.user.roles.includes("teacher")){
                    window.location.assign("http://" + window.location.host + "/groups")
                }else{
                    alert("No tienes permisos suficientes para acceder a la aplicación")
                }

                return user.token
            }).catch(e =>{
                alert("El usuario o la constraseña son incorrectos")
            })
        })
        
        document.querySelector("#loginSub").click()
    }

    render(){
        return (
            <button type = "button" className= "boton-gen" onClick = {()=>{this.login()}}>Iniciar Sesión</button>
        )
    }
}

export default Button;