import React from 'react';
import Input from './components/input/input'
import Title from './components/title/tittle'
import Logo from './components/Logo/logo'
import Button from '../../commons/Button/button';
import './login.css'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
    contenido:{
        height: "100vh"
    },
    botonLog:{
        padding: "20px"
    }
});

const Login = () => {
   
    const classes = useStyles()
     return (
         <Grid
            container
            direction = "row"
            justify = "center"
            alignItems = "center"
            className = "contenido"
        >
             <Grid item={true} xs = {12} md = {6} md = {6} className = "formulario">
                 <Grid 
                    container
                    direction = "column"
                    justify = "center"
                    alignItems = "center"
                 >
                    <Title text = "Turo Analytics" subtitle = "Entrada secreta"></Title>
                    <Grid className = "inputs">
                       <Input></Input>
                     </Grid>
                     <Grid className = "botonLog" >
                        <Button type = "submit" text = "Iniciar sesión"></Button>
                     </Grid>
                </Grid>
             </Grid>
             <Grid item={true} xs ={0} md = {6} md = {6} className = "logos">
                <Logo></Logo>
             </Grid>
         </Grid>
     )
}

export default Login;