import {React, useState, useEffect} from 'react'
import Cell from './cell'
import Grid from '@material-ui/core/Grid';
import './floor.css'
import GetDataUsers from '../../analitic_data';

const Floor = (props) => {
   
    const done = props.done
    const buyed = props.buyed
    const without_buy = props.without_buy
    const total = props.total
    console.log("done")
    console.log(done[0].length)
    const items = []
  
    if(done.length == 0){
        items.push(<p>"Cargando página"</p>)
    }else{
        for (let i = 0; i < done[0].length; i++) {
          items.push(
              <Grid item={true} key = {"gridcell"+i+props.piso} className= 'celda-floor' sm = {1}>
                  <Cell key = {"cell"+i+props.piso}
                    done = {done[0][i]}
                    total = {total[0][i]}
                    buyed = {buyed[0][i]}
                    without_buy = {without_buy[0][i]}
              ></Cell></Grid>)
        }
        console.log("items")
        console.log(items)
        console.log(done)
    }

    return(
        <div>
        <Grid container sm = {12}>
            <Grid item={true} sm = {1}></Grid>
            <Grid item={true} className = 'piso-texto' sm = {1}>Piso {props.piso}</Grid>
            {items}
        </Grid>

        {/* <div>
            <ul>
              {data.count.map(item => (
                <li key={item.total_books}>
                    {item.skill}
                </li>
                ))}
            </ul>
            
        </div> */}
        </div>
    )
}

export default Floor;