import {React, useState, useEffect} from 'react';
import {Link} from 'react-router-dom'
import BarMenu from '../../commons/barMenu/barmenu'
import Grid from '@material-ui/core/Grid';
import GroupBar from './groupBar'
import './groups.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { GridListTileBar } from '@material-ui/core';

const Groups = () => {

    const [groupCompS, setGroupCompS] = useState([]);
    const [semestres_unicos, setSemestres_unicos] = useState([]);
    const [classToBg, setClassToBg] = useState("")

    let semBut = (a) => {

      console.log(a)
      console.log("#sem" + a)

      let elem = document.querySelector("#sem" + a)
      console.log(elem)

      // let elem = document.querySelector("#sem" + a)

      // if(elem.style.visibility == 'visible'){
      //   elem.style.visibility = 'hidden'
      //   elem.style.display = 'none'
      //   document.querySelector(".expand-right-element").style.display = "block"
      //   document.querySelector(".expand-down-element").style.display = "none"
      //   setClassToBg("root")
      // }else{
      //   elem.style.visibility = 'visible'
      //   elem.style.display = 'block'
      //   document.querySelector(".expand-down-element").style.display = "block"
      //   document.querySelector(".expand-right-element").style.display = "none"
      //   setClassToBg("root")
      // }
    }  

    useEffect(() => {

      let groupComp = []
     
      function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }
  
      const getGroups = () => {
        let token = "Bearer " + JSON.parse(localStorage.getItem("login")).token;
        console.log(token)
        fetch("https://aws.profesorvirtual.cloudns.cl:3000/user-info/teacher-groups",{
          method:"GET",
          headers:{
            "Authorization": token,
          }
          }).then((res) => {
            if(res.ok){
              return res.json()
          }
          }).then((res) => {

            let all_semestres = []
  
            for(let i = 0 ; i < res.length; i++){
              all_semestres[i] = res[i].semesterGroup.semester
            }
  
            setSemestres_unicos(all_semestres.filter(onlyUnique))

            let semestres = all_semestres.filter(onlyUnique)
  
            

            console.log("semestres")
            console.log(semestres)
            
            for(let sem in semestres){
              let temp = []
              let salida = res.filter((e) => e.semesterGroup.semester == semestres[sem])
              for(let i = 0; i < salida.length ; i++){
                temp.push(
                  <Grid sm = {12} id = {sem}>
                    <GroupBar course_group = {salida[i].group_name} name_course = {salida[i].courseGroup.course_name} group_name = {salida[i].group_name} id_semester = {salida[i].id_semester} id_course = {salida[i].id_course} semestre = {res[i].id_semester}></GroupBar>
                  </Grid>
                )
              }
              groupComp.push(
                <Grid container>
                <Grid sm = {1}>
                  </Grid>
                  <Grid sm = {1}>
                  </Grid>
                    <Grid sm = {10} className = "text-semestre">
                      <button className = "semBut" onClick = {() => {semBut(semestres[sem])}}>
                        <FontAwesomeIcon icon={faAngleDown} className = "expand-down-element"/>
                        <FontAwesomeIcon icon={faAngleRight} className = "expand-right-element"/>
                        <span className = "sem">Semestre {semestres[sem]}</span>
                      </button>
                    </Grid>
                    <Grid sm = {12} id = {"sem" + semestres[sem]}>
                      {temp}
                    </Grid>
                </Grid>
              )
            }

            setClassToBg("root")
            setGroupCompS(groupComp)
        }).catch((error)=>{
            console.log(error)
        })
      }
  
 
      getGroups()
    }, []);



    return(
      <div className = {classToBg} id = "wellcome">
        <BarMenu></BarMenu>
        <Grid container>
          <Grid id = "prueba" sm = {1}>
          </Grid>

          <Grid sm = {10}>
              <p className = "tit-wll">Turo Analítica</p>
          </Grid>
          {groupCompS}
        </Grid>
      </div>
    )
}

export default Groups;

