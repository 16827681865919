import {React, useState, useEffect} from 'react';
import Title from '../login/components/title/tittle'
// import Logo from './components/Logo/logo'
import Logo from '../login/components/Logo/logo'
import Button from '../../commons/Button/button';
import GroupBar from '../groups/groupBar'
// import './login.css'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
    contenido:{
        height: "100vh"
    },
    botonLog:{
        padding: "20px"
    }
});

const Home = () => {
   
    const [showHome, setShowHome] = useState([]);

    const [groupCompS, setGroupCompS] = useState([]);
    const [semestres_unicos, setSemestres_unicos] = useState([]);
    const [classToBg, setClassToBg] = useState("")

    
    useEffect(() => {
        
        let groupComp = []

        function onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
        }

        const getGroups = () => {
          let token = "Bearer " + JSON.parse(localStorage.getItem("login")).token;
            fetch("https://aws.profesorvirtual.cloudns.cl:3000/user-info/teacher-groups",{
              method:"GET",
              headers:{
                "Authorization": token,
              }
              }).then((res) => {
                if(res.ok){
                  return res.json()
              }
              }).then((res) => {
                console.log("AQUI VAN LOS GRUPOS: ===========")
                console.log(res)
      
                let all_semestres = []
      
                for(let i = 0 ; i < res.length; i++){
                  all_semestres[i] = res[i].semesterGroup.semester
                }
      
                setSemestres_unicos(all_semestres.filter(onlyUnique))
      
                let salida = res.filter((e) => e.semesterGroup.semester == "2021-1")
                // console.log(salida)
      
                for(let i = 0; i < res.length ; i++){
                  let course_group = res[i].group_name
                  let name_course = res[i].courseGroup.course_name
                  groupComp.push(<GroupBar course_group = {course_group} name_course = {name_course} group_name = {res[i].group_name} id_semester = {res[i].id_semester} id_course = {res[i].id_course} semestre = {res[i].id_semester}></GroupBar>)
                }
                
                setClassToBg("root")
                // console.log("groupComp")
                setGroupCompS(groupComp)
                // console.log(groupComp)
            })
          }
        //   getGroups()
    }, []);

    const classes = useStyles()
     return (
         <Grid
            container
            direction = "row"
            justify = "center"
            alignItems = "center"
            // alingContent = "center"
            className = {classes.contenido}
        >
             <Grid item={true} xs = {12} md = {6} md = {6} className = "formulario">
                 <Grid 
                    container
                    direction = "column"
                    justify = "center"
                    alignItems = "center"
                 >
                     <Grid>
                        <Title text = "Turo Analytics" subtitle = "Wellcome Analytics service for the english area courses at Universidad del Rosario"></Title>
                     </Grid>
                     
                     <Grid className = {classes.botonLog} >
                        <button type = "button" className= "boton-gen">Ir a grupos</button>
                     </Grid>
                </Grid>
             </Grid>
             <Grid item={true} xs ={0} md = {6} md = {6} className = "logos">
                <Logo></Logo>
             </Grid>
         </Grid>
     )
}

export default Home;