import Modal from 'react-modal'
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import "./modal.css"
import Grid from '@material-ui/core/Grid';

export default function ModalStudent({onClick, open, student}) {
    const [isOpen, setIsOpen] = React.useState(false);
    let salida = ""
    if(student.userRelationships){
        salida = []
        for(let i = 0; i < student.userRelationships.length; i++){
            salida.push(<div>
                <hr></hr>
                <p>{student.userRelationships[i].typeuser.type_user}</p>
            </div>)
        }
        // salida = <div>
        //     <p>{student.userRelationships[0].typeuser.type_user}</p>
        // </div>
        if(student.userPrograms){
            for(let i= 0; i< student.userPrograms.length; i++){
                salida.push(
                    <div>
                        <p>{student.userPrograms[i].program.program}</p>
                        <p>Semestre: {student.userPrograms[i].semestre}</p>
                        <p>Creditos obligatorios aprobados: {student.userPrograms[i].obligatoria_con}</p>
                        <p>Creditos requeridos: {student.userPrograms[i].obligatoria_req}</p>
                    </div>
                )
            }
        }
    }
    return (
        <div>
            <Grid container>
                {/* <Grid item sm = {6}> */}
                <Modal className = "modal-students" isOpen={open}>
                    <Grid container>
                        <Grid sm = {1}></Grid>
                        <Grid sm ={6}>
                            <h1 className = "name">{student.firstName + " " + student.lastName}</h1>
                            <h3 className = "email">{student.email}</h3>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid sm = {1}></Grid>
                        <Grid item>
                            <h2 className = "subtitles">Información del jugador:</h2>
                            <p>{salida}</p>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item sm = {10}></Grid>
                        <Grid item sm = {2}>
                            <button className = "btn-est-modal" onClick = {onClick}>Cerrar</button>
                        </Grid>
                    </Grid>
                </Modal>
                </Grid>
            {/* </Grid> */}
        </div>
    ); 

}

