import React from 'react'
import './cell.css'

const Cell = (props) => {

    let v1 = (props.done/props.total)*100 + "%";
    let v2 = (props.buyed/props.total)*100 + "%"
    let v3 = (props.without_buy/props.total)*100 + "%"
    let v4 = (1 - props.without_buy/props.total)*100 + "%"

    return(
        <div className = 'rootCell'>
            <div className = 'cell'>
                <div className = "">
                    <p className = "cell-text"> {props.done} / {props.total}</p>
                </div>
                <div className = "cellbar-v1 cellbar tooltip" style={{width: v1}}>
                    <span className="tooltiptext">Completados {props.done}</span>
                </div>
                <div className = "cellbar-v2 cellbar tooltip" style={{width: v2, left:v1}}>
                    <span className="tooltiptext">Sin completar {props.buyed}</span>
                </div>
                <div className = "cellbar-v3 cellbar tooltip" style={{width: v3, left:v4}}>
                    <span className="tooltiptext">Sin comprar {props.without_buy}</span>
                </div>
            </div>
        </div>
    )
}

export default Cell;