import React, { useState, useEffect } from 'react';
import GetDataUsers from './analitic/analitic_data'

function useGiphy(query, id_user) {
  const [results, setResults] = useState([]);
  const [progress, setProgress] = useState([])
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const response = await GetDataUsers({url: "/user-info/"+ id_user + "/count-books-by-room-skill"});
        const progress = await GetDataUsers({url: "/user-info/"+ id_user + "/progress"});
        const json = await response.json();
        const jsonProg = await progress.json();

        setResults(
          json.data.map(item => {
            return item.count;
          })
        );
        
        setProgress(
          jsonProg.data.map(item => {
            return item;
          })
        );
        
      } finally {
        setLoading(false);
      }
    }

    if (query !== '') {
      fetchData();
    }
  }, [query]);

  return [results, loading];
}

export default function AsyncHooks(props) {
    
  let id_us = this.props.location.state.id_user

  const [search, setSearch] = useState('');
  const [query, setQuery] = useState('');
  const [results, loading] = useGiphy(query, id_us);


  return (
    <div>
      <h1>Books by room</h1>
      <form
        onSubmit={e => {
          e.preventDefault();
          setQuery(search);
        }}
      >
        <input
          value={search}
          onChange={e => setSearch(e.target.value)}
          placeholder="Numero de libros"
        />
        <button type="submit">Search</button>
      </form>
      <br />
      {loading ? (
        <h1>Libros por piso</h1>
      ) : (
        results.map(item => console.log(item))
      )}
    </div>
  );
}
